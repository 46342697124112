import { useForm } from 'react-hook-form'
import Icon from 'rv-components-onze/src/Icon'
import Loader from 'components/Loader'

import TextField from 'components/Fields/Text'
import PhoneField from 'components/Fields/Phone'
import EmailField from 'components/Fields/Email'
import FeedbackForm from 'components/FeedbackForm'
import Condition from 'components/Condition'

import enhancer from './hooks'

import Routes from 'Routes'
import { noWhiteSpace } from 'utils/validators/text'

import Typography from 'rv-components-onze/src/Typography'
import Button from 'rv-components-onze/src/Button'


const { privacyPolice } = Routes

const Form = ({
  onSubmit,
  feedbackIsTrue,
  isLoading,
  onClickFeedback,
  btnText = 'Agendar',
}) => {
  const { handleSubmit, control } = useForm()

  return (
    <Condition
      condition={!feedbackIsTrue}
      elseCondition={<FeedbackForm onClick={onClickFeedback} />}>
      {isLoading ? (
        <Loader />
      ) : (
          <div className="conversation-form-card">
            <Typography as="h2" variant="subtitle" weight="normal" className="conversation-form-card__title">
              Agende uma conversa <strong>sem compromisso</strong>
            </Typography>

            <form className="conversation-form" onSubmit={handleSubmit(onSubmit)}>

              <div className="conversation-form__field-group">
                <TextField
                  control={control}
                  name="name"
                  label="Nome"
                  placeholder="Nome completo"
                />
              </div>

              <div className="conversation-form__field-group">
                <EmailField
                  control={control}
                  name="email"
                  label="Email corporativo"
                  placeholder="seuemail@empresa.com.br"
                />
              </div>

              <div className="conversation-form__field-group">
                <PhoneField
                  control={control}
                  name="phone"
                  label="Telefone"
                  placeholder="(00) 0 0000 0000"
                />
              </div>

              <div className="conversation-form__field-group">
                <TextField
                  control={control}
                  name="company"
                  label="Empresa"
                  placeholder="Nome da empresa"
                />
              </div>

              <div className="conversation-form__btn-wrapper">
                <Button
                  label={btnText}
                  customClass="conversation-form__submit"
                  type="sumbit"
                  buttonSize="large"
                  iconId='right-arrow'
                  iconPosition='right'
                />
              </div>


            </form>

            <Typography as="a" variant="caption" href={privacyPolice.path} className="conversation-form-card__privacy-text">
              Ver políticas de privacidade
            </Typography>


          </div>
        )}
    </Condition>
  )
}

export default enhancer(Form)
