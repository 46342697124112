import Header from 'components/Header'
import FooterNew from 'components/FooterNew'
import Menu from 'components/Menu'
import Sidebar from 'components/Sidebar'
import SectionForm from 'components/SectionForm'
import SectionCombinedFeatures from './components/SectionCombinedFeatures'
import ConversationForm from 'components/ConversationSectionForm'
import Hero from './components/Hero'
import SectionWithOnze from './components/SectionWithOnze'
import SectionPoweredBy from './components/SectionPoweredBy'
import OnzeForYourCompany from './components/OnzeForYourCompany'

import enhancer from './hooks'

const About = () => {
  return (
    <div>
      <Header />
      <Hero />
      <Menu />
      <Sidebar>
        <SectionForm customClass="sidebar" />
      </Sidebar>
      <SectionWithOnze />
      <SectionPoweredBy />
      <OnzeForYourCompany />
      <SectionCombinedFeatures />
      <ConversationForm />
      <FooterNew />
    </div>
  )
}

export default enhancer(About)
