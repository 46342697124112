import Typography from 'rv-components-onze/src/Typography'

const SectionPoweredBy = () => {
  return (
    <section className="powered-by">
      <div className="container">
        <div className="powered-by__content">
          <Typography as="h2" variant="headline5" className="powered-by__text">
            Somos uma empresa powered by
          </Typography>
          <div className="powered-by__companies">
            <a href="https://www.redventures.com/">
              <img
                className="powered-by__companies__logo"
                src="/images/logo-rv.png"
                alt="logo Red Ventures"
              />
            </a>
            <a href="https://ribbitcap.com/">
              <img
                className="powered-by__companies__logo--ribbit"
                src="/images/logo-ribbit.png"
                alt="logo Ribbit Capital"
              />
            </a>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SectionPoweredBy
