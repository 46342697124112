export const listItems = [
  {
    text: ['Previdência', <b> 100% digital</b>],
  },
  {
    text: ['Check-ups regulares de', <b> saúde financeira</b>],
  },
  {
    text: [
      <b>Consultoria individualizada</b>,
      ' com especialistas financeiros',
    ],
  },
  {
    text: ['Tudo pelo aplicativo e', <b> sem burocracia </b>, 'para o RH'],
  },
]
