import Typography from 'rv-components-onze/src/Typography'
import Button from 'rv-components-onze/src/Button'
import Icon from 'rv-components-onze/src/Icon'
import Img from 'components/Img'
import Routes from 'Routes'

import { listItems } from './constants'

const { yourCompany } = Routes

const OnzeForYourCompany = () => {
  return (
    <section className="onze-for-your-company">
      <div className="container onze-for-your-company__content">
        <div className="onze-for-your-company__content-image">
          <Img
            className="onze-for-your-company__image"
            src="/using-app.png"
            alt="cellphone"
          />
        </div>
        <div className="onze-for-your-company__text">
          <div className="onze-for-your-company__content-image onze-for-your-company__content-image--mobile">
            <Img
              className="onze-for-your-company__image"
              src="/using-app.png"
              alt="cellphone"
            />
          </div>
          <div className="onze-for-your-company__body">
            <Typography
              as="h2"
              variant="subtitle"
              weight="bold"
              className="onze-for-your-company__body-title">
              5 razões para ter a Onze na sua empresa
            </Typography>
            <ul className="onze-for-your-company__list">
              {listItems.map((info, i) => {
                return (
                  <li className="onze-for-your-company__item" key={i}>
                    <div className="onze-for-your-company__wrapper-icon">
                      <Icon
                        iconId="check-icon-filled"
                        customClass="onze-for-your-company__icon"
                      />
                    </div>
                    <Typography
                      as="p"
                      variant="body1"
                      className="onze-for-your-company__body-list">
                      {info.text}
                    </Typography>
                  </li>
                )
              })}

              <Button
                as="a"
                href={yourCompany.path}
                label="Saiba mais"
                iconId="right-arrow"
                iconPosition="right"
                buttonStyle="text"
                customClass="onze-for-your-company__button"
              />
            </ul>
          </div>
        </div>
      </div>
    </section>
  )
}

export default OnzeForYourCompany
