import Typography from 'rv-components-onze/src/Typography'
import Button from 'rv-components-onze/src/Button'

import Img from 'components/Img'

import Routes from 'Routes'

const { financialHealth } = Routes

const SectionCombinedFeatures = () => {
  return (
    <section className="combined-features">
      <div className="container">
        <div className="combined-features__text-side">
          <Typography
            as="h2"
            variant="headline5"
            className="combined-features__title">
            Por que combinamos Previdência com Saúde Financeira?
          </Typography>

          <Typography
            as="p"
            variant="body1"
            className="combined-features__paragraph">
            Além do retorno do INSS ser insuficiente para as pessoas, o
            brasileiro não tem costume de poupar dinheiro. Ou seja, é uma
            receita para o estresse e fracasso financeiro.
          </Typography>

          <Typography
            as="p"
            variant="body1"
            className="combined-features__paragraph">
            Por meio da <b>saúde financeira,</b> queremos educar as pessoas
            sobre a importância de se planejar​, desmistificar noções de
            investimentos e previdência​ e, ajudar a criar capacidade e urgência
            para poupar e investir​.
          </Typography>

          <Typography
            as="p"
            variant="body1"
            className="combined-features__paragraph">
            A <b>previdência</b> é o instrumento mais eficiente do mercado, e
            por meio da Onze, incentivamos as pessoas a criarem disciplina com
            aportes em folha​, encorajando investimento para longo prazo com
            incentivos fiscais​ e, possibilitando à empresas motivarem
            colaboradores de forma eficiente.
          </Typography>

          <Button
            as="a"
            HREF={financialHealth.path}
            label="Saiba mais"
            iconId="right-arrow"
            iconPosition="right"
            buttonStyle="text"
            customClass="combined-features__button"
          />
        </div>
        <div className="combined-features__video-wrapper">
          <iframe
            className="combined-features__video-wrapper__iframe"
            src="https://www.youtube.com/embed/TP-CWhBpSQA"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen></iframe>
        </div>
      </div>
    </section>
  )
}

export default SectionCombinedFeatures
