import Typography from 'rv-components-onze/src/Typography'

import Img from 'components/Img'

const Hero = () => {
  return (
    <section className="about-hero">
      <div className="about-hero__figure"></div>
      <div className="container">
        <div className="about-hero__content">
          <Typography
            as="h2"
            variant="headline4"
            className="about-hero__title"
          >
            O que faz a Onze
            <br />ser a Onze
          </Typography>
          <div className="about-hero__body">
            <Typography as="p" variant="body1" className="about-hero__text">
              Não é de hoje que o estresse financeiro preocupa milhões e milhões de brasileiros. Sabemos que o planejamento financeiro exige atenção e, por isso, chegamos para ressignificar a forma que as pessoas se relacionam com as finanças, para que consigam traçar objetivos reais e a longo prazo sem dor.
            </Typography>
          </div>
        </div>
      </div>
      <div className="about-hero__video">
        <iframe className="about-hero__iframe" src="https://www.youtube.com/embed/cB9OLZOF2k8" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
      </div>
    </section>
  )
}

export default Hero