import Icon from 'rv-components-onze/src/Icon'

const FeedbackForm = ( { onClick } ) => {
  return (
    <div className="feedback-form">
      <Icon iconId="close" onClick={onClick}/>
      <Icon iconId="sucess-icon"/>
      <h1 className="feedback-form__title">Solicitação enviada com sucesso</h1>
    </div>
  )
}

export default FeedbackForm
