import { useEffect } from 'react'
import animationObserver from 'utils/animationObserver'

const useAnimationObserver = () => {

  useEffect(() => {
    animationObserver()
  }, [])
}

export default useAnimationObserver