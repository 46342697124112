import Icon from "rv-components-onze/src/Icon"
import Typography from "rv-components-onze/src/Typography"

import Form from '../ConversationForm';

import enhancer from "./hooks"

const ConversationSectionForm = () => {
  return (
    <section className="conversation-section-form" data-show>
      <div className="conversation-section-form__text-side">
        <div className="conversation-section-form__text-side-holder">
          <Icon
            iconId="grafismo-onze"
            customClass="conversation-section-form__text-side-icon"
          />
          <Typography as="h1" variant="headline4" weight="bold" className="conversation-section-form__text-side-title">
            Sua empresa pode ir além
          </Typography>

          <Typography as="h2" variant="subtitle" weight="normal" className="conversation-section-form__text-side-subtitle">
            Mais saúde financeira para seus colaboradores
          </Typography>
        </div>
        <Icon
          iconId="dots"
          customClass="conversation-section-form__text-side-dots"
        />
      </div>
      <div className="conversation-section-form__form-side">
        <span className="square"></span>
        <Form />
      </div>
    </section>
  )
}

export default enhancer(ConversationSectionForm)