import Typography from 'rv-components-onze/src/Typography'

const SectionWithOnze = () => {
  return (
    <section className="section-with-onze">
      <div className="container">
        <div className="section-with-onze__content">
          <Typography
            as="p"
            variant="subtitle"
            className="section-with-onze__text">
            A Onze é a <b>primeira fintech</b> que traz uma solução de saúde
            financeira com acumulação de patrimônio para colaboradores da sua
            empresa, através de uma previdência diferente e moderna.
          </Typography>
        </div>
      </div>
    </section>
  )
}

export default SectionWithOnze
